import Component 	from '../colorz/Component';

import BaseCanvas 	from './BaseCanvas';

module.exports = class SquareCanvas extends Component {
	onInit( el ) {
		this.setOffset 			= this.setOffset.bind( this );
		this.setPerlin 			= this.setPerlin.bind( this );
		this.setDisplacement	= this.setDisplacement.bind( this );
		this.forceSize			= this.forceSize.bind( this );

		this.el 				= el;

		this.canvas 			= document.createElement( 'canvas' );
		this.context 			= this.canvas.getContext( '2d' );
		this.offset 			= { top: 0, left: 0 };
		this.perlin 			= { x: 0, y: 0 };
		this.isSizeForced 		= false;

		this.setStyle();
	}

	onReady() {
		this.onResize();

		this.el.appendChild( this.canvas );
	}

	onLoad() {
        this.onResize();
  }

	onResize() {
		if( this.isSizeForced ) {
			return;
		}

		this.canvas.width = this.el.offsetWidth;
		this.canvas.height = this.el.offsetHeight;
	}

	forceSize(width, height) {
		this.isSizeForced 	= true;

		this.canvas.width 	= width;
		this.canvas.height 	= height;
	}

	onUpdate() {
		this.context.drawImage( BaseCanvas.canvas, this.offset.left + this.perlin.x + this.displacement.x, 0, this.canvas.width, this.canvas.height, 0, 0, this.canvas.width, this.canvas.height );
	}

	setOffset( offset ) {
		this.offset = offset;
	}

	setDisplacement( displacement ) {
		this.displacement = displacement;
	}
	setPerlin( perlin ) {
		this.perlin = perlin;
	}

	setStyle() {
		this.canvas.style.position 	= 'absolute';
		this.canvas.style.top 		= '0';
		this.canvas.style.left 		= '0';
	}
}
