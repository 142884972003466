import Component 			from '../colorz/Component';

import getAbsoluteOffset 	from '../colorz/utils/getAbsoluteOffset';
import Easing 				from '../colorz/utils/Easing';

import SquareCanvas 		from './SquareCanvas';
import BaseCanvas 			from './BaseCanvas';

module.exports = class SquareWording extends Component {
	onInit( el ) {
		this.onNext = this.onNext.bind( this );

		this.wordingsEl 	= document.querySelectorAll('.js-wording');
		this.wrapper 		= document.querySelector('.js-wordings-wrapper');
		this.wordings 		= new Array();
		this.currentIndex 	= 0;
		this.canvas 		= new SquareCanvas( this.wrapper );
		this.isActive 		= false;
		this.duration 		= 600;

		for( let i = 0 ; i < this.wordingsEl.length ; i++ ) {
			this.wordings.push({
				el: this.wordingsEl[i],
				width: 0,
				height: 0,
				isActive: i == 0 ? true : false
			});
		}

		this.canvas.setPerlin( { x: 0, y:0 } );
		this.canvas.setDisplacement( { x: 0, y:0 } );
		this.canvas.forceSize( 15, 15 );
	}

	onReady() {
		this.onResize();
	}

	onLoad() {
		setTimeout( this.onNext, 3000 );
	}

	onNext() {
		let nextIndex 		= this.currentIndex + 1 == this.wordings.length ? 0 : this.currentIndex + 1;
		this.heighest 		= this.getHighest( new Array(this.wordings[ this.currentIndex ], this.wordings[ nextIndex ] ) );
		this.heightTarget 	= this.heighest == 0 ? this.wordings[ this.currentIndex ].height : this.wordings[ nextIndex ].height;

		this.currentIndex 	= nextIndex;

		this.expand();
	}

	expand() {
		this.easingHeight = new Easing({
			type: 'easeOutQuint',
			duration: this.duration,
			start: 15,
			end: this.heightTarget,
			callback: ()=>{}
		});
		this.easingHeight.isActive = true;

		setTimeout( ()=>{
			this.easingWidth = new Easing({
				type: 'easeOutQuint',
				duration: this.duration,
				start: 15,
				end: this.width,
				callback: ()=>{
					this.easingWidth.onDestroy();
					this.easingWidth = null;
					this.easingHeight.onDestroy();
					this.easingHeight = null;
					this.isActive = false;
					this.changeWording();
				}
			});

			this.easingWidth.isActive = true;
		}, this.duration )

		this.isActive = true;
	}

	reduce() {
		this.easingHeight = new Easing({
			type: 'easeInOutQuint',
			duration: this.duration,
			start: this.heightTarget,
			end: 15,
			callback: ()=>{
				this.easingHeight.onDestroy();
				this.easingHeight = null;
				this.isActive = false;
			}
		});

		this.easingWidth = new Easing({
			type: 'easeInOutQuint',
			duration: this.duration,
			start: this.width,
			end: 15,
			callback: ()=>{
				this.easingWidth.onDestroy();
				this.easingWidth = null;
				this.isActive = false;

				setTimeout( this.onNext, 3000 );
			}
		});

		this.easingHeight.isActive = true;
		this.easingWidth.isActive = true;

		this.isActive = true;
	}

	changeWording() {
		for( let i = 0 ; i < this.wordings.length ; i++ ) {
			if( i === this.currentIndex ) {
				this.wordings[i].el.style.opacity = 1;
			}
			else {
				this.wordings[i].el.style.opacity = 0;
			}
		}

		BaseCanvas.onPointerdown();

		setTimeout( ()=>{
			this.reduce();
		}, 200);
	}

	onUpdate() {
		this.canvas.forceSize( this.easingWidth != void 0 ? this.easingWidth.value : 15,  this.easingHeight != void 0 ? this.easingHeight.value : 15 );
	}

	onResize() {
		this.offset = getAbsoluteOffset( this.wrapper );
		this.canvas.setOffset( this.offset );

		this.width = this.wrapper.offsetWidth;

		for( let i = 0 ; i < this.wordings.length ; i++ ) {
			let wording = this.wordings[i];

			wording.width 	= wording.el.offsetWidth;
			wording.height 	= wording.el.offsetHeight;
		}

		let highest = this.getHighest( this.wordings );
		this.wrapper.style.height = this.wordings[highest].height + 'px';
	}

	getHighest(array) {
		let highest = 0;

		for( let i = 1 ; i < array.length ; i++ ) {
			if( array[i].height > array[highest].height ) {
				highest = i;
			}
		}

		return highest;
	}
}
