import Component 			from '../colorz/Component';

import Easing 				from '../colorz/utils/Easing';
import getAbsoluteOffset 	from '../colorz/utils/getAbsoluteOffset';
import device 				from '../colorz/utils/device';

class BaseCanvas extends Component {
	onInit( el ) {
		this.buildArrayEasings 	= this.buildArrayEasings.bind( this );
		this.onStepEnding 		= this.onStepEnding.bind( this );
		this.onPointerdown 		= this.onPointerdown.bind( this );

		this.canvas = null;
		this.colors = new Array(
			new Array(
				{r: 214,	g: 93, 		b:177},
				{r: 255, 	g: 150, 	b:113},
				{r: 255,	g: 199, 	b:95}
			),
			new Array(
				{ r: 54, g: 209, b: 220 },
				{ r: 81, g: 175, b: 224 },
				{ r: 89, g: 135, b: 229 }
			),
			new Array(
				{ r: 101, g: 78, b: 163 },
				{ r: 158, g: 119, b: 179 },
				{ r: 234, g: 175, b: 200 }
			),
			new Array(
				{r: 0,	g: 137, b:186},
				{r: 0, 	g: 142, b:155},
				{r: 0, 	g: 143, b:122}
			),
		);

		this.currentStep 	= 0;
		this.currentArray 	= 0;
		this.nbrArray 		= this.colors.length;
		this.buildArrayEasings();
		this.isActive 		= false;
		this.isTransition 	= false;
	}

	onReady() {
		this.canvas 	= document.createElement('canvas');
		this.context 	= this.canvas.getContext("2d");
		this.onResize();

		this.isActive 	= true;

		// document.body.appendChild( this.canvas );
		// window.addEventListener(device.pointerdown, this.onPointerdown);
	}

	onResize() {
		this.canvas.width   = window.innerWidth;
		this.canvas.height  = 500;
	}

	onUpdate() {
		this.gradient = this.context.createLinearGradient(0, 0, this.canvas.width, this.canvas.height);

		this.gradient.addColorStop(0, 'rgba(' + Math.round( this.easingGradient[0][0].value ) + ',' + Math.round( this.easingGradient[0][1].value ) + ',' + Math.round( this.easingGradient[0][2].value ) + ', 1)');
		this.gradient.addColorStop(.5,'rgba(' + Math.round( this.easingGradient[1][0].value ) + ',' + Math.round( this.easingGradient[1][1].value ) + ',' + Math.round( this.easingGradient[1][2].value ) + ', 1)');
		this.gradient.addColorStop(1, 'rgba(' + Math.round( this.easingGradient[2][0].value ) + ',' + Math.round( this.easingGradient[2][1].value ) + ',' + Math.round( this.easingGradient[2][2].value ) + ', 1)');

		this.context.fillStyle = this.gradient;
		this.context.fillRect( 0, 0, this.canvas.width, this.canvas.height )
	}

	onStepEnding() {
		if( this.isTransition ) { return; }
		this.currentStep = this.getNextStep( this.currentStep, this.colors[ this.currentArray ].length );

		this.buildArrayEasings();
	}

	getNextStep( current, nbrStep ) {
		return ( current + 1 ) % nbrStep;
	}

	getNextArray( current ) {
		return ( current + 1 ) % this.colors.length;
	}

	buildArrayEasings() {
		this.easingGradient = new Array(
			this.buildEasing( ( this.currentStep + 0 ) % this.colors[ this.currentArray ].length ),
			this.buildEasing( ( this.currentStep + 1 ) % this.colors[ this.currentArray ].length ),
			this.buildEasing( ( this.currentStep + 2 ) % this.colors[ this.currentArray ].length ),
		);

		this.easingGradient[0][0].callback = this.onStepEnding;
	}

	buildEasing( index ) {
		let array = new Array(
			new Easing({
				type: 'linear',
				duration: 1000,
				start: this.colors[ this.currentArray ][ index ].r,
				end: this.colors[ this.currentArray ][ this.getNextStep( index, this.colors[ this.currentArray ].length ) ].r
			}),
			new Easing({
				type: 'linear',
				duration: 1000,
				start: this.colors[ this.currentArray ][ index ].g,
				end: this.colors[ this.currentArray ][ this.getNextStep( index, this.colors[ this.currentArray ].length ) ].g
			}),
			new Easing({
				type: 'linear',
				duration: 1000,
				start: this.colors[ this.currentArray ][ index ].b,
				end: this.colors[ this.currentArray ][ this.getNextStep( index, this.colors[ this.currentArray ].length ) ].b
			})
		);

		for( let i = 0 ; i < array.length ; i++ ) {
			array[i].isActive = true;
		}

		return array;
	}

	onPointerdown() {
		this.currentArray = this.getNextArray( this.currentArray );

		this.easingGradient = new Array(
			this.buildTransitionEasing( 0 ),
			this.buildTransitionEasing( 1 ),
			this.buildTransitionEasing( 2 ),
		);

		this.isTransition = true;
		this.easingGradient[0][0].callback = ()=>{
			this.isTransition 	= false;
			this.currentStep 	= 0;
			this.onStepEnding();
		}
	}

	buildTransitionEasing( index ) {
		let array = new Array(
			new Easing({
				type: 'easeInOutQuad',
				duration: 1000,
				start: this.easingGradient[index][0].value,
				end: this.colors[ this.currentArray ][ this.getNextStep( index, this.colors[ this.currentArray ].length ) ].r
			}),
			new Easing({
				type: 'easeInOutQuad',
				duration: 1000,
				start: this.easingGradient[index][1].value,
				end: this.colors[ this.currentArray ][ this.getNextStep( index, this.colors[ this.currentArray ].length ) ].g
			}),
			new Easing({
				type: 'easeInOutQuad',
				duration: 1000,
				start: this.easingGradient[index][2].value,
				end: this.colors[ this.currentArray ][ this.getNextStep( index, this.colors[ this.currentArray ].length ) ].b
			})
		);

		for( let i = 0 ; i < array.length ; i++ ) {
			array[i].isActive = true;
		}

		return array;
	}
}

export default new BaseCanvas();
