import Component 			from '../colorz/Component';

import device 				from '../colorz/utils/device';
import getAbsoluteOffset	from '../colorz/utils/getAbsoluteOffset';
import stylizer				from '../colorz/utils/stylizer';
import getData				from '../colorz/utils/getData';
import Vector2				from '../colorz/utils/Vector2';
import clamp				from '../colorz/utils/clamp';

import PerlinNoise 			from '../utils/PerlinNoise';

import SquareCanvas 		from './SquareCanvas';

module.exports = class SquareItem extends Component {
	onInit( el ) {
		this.onPointerEnter 	= this.onPointerEnter.bind( this );
		this.setDeltaMouse 		= this.setDeltaMouse.bind( this );

		this.el 				= el;
		this.clock 				= 0;
		this.perlin 			= { x: 0, y:0 };
		this.displacement 		= { x: 0, y:0 };
		this.center 			= { x: 0, y:0 };
		this.canvas 			= new SquareCanvas( this.el );
		this.interactions 		= new Array();
		this.isPerlin 			= getData( this.el, 'is-perlin' );
		this.isDisplacement 	= getData( this.el, 'is-displacement' );

		this.canvas.setPerlin( this.perlin );
		this.canvas.setDisplacement( this.displacement );
	}

	onReady() {
		this.onResize();
		this.el.addEventListener(device.pointerenter, this.onPointerEnter);
	}

	onResize() {
		this.offset = getAbsoluteOffset( this.el );
		this.center.x = this.el.offsetWidth * .5 + this.offset.left;
		this.center.y = this.el.offsetHeight * .5 + this.offset.top;
		this.canvas.setOffset( this.offset );
	}

	onUpdate( delta ) {
		this.clock += delta * .0007;

		this.calculateInteractions( delta );

		if( this.isPerlin ) {
			this.perlin.x = PerlinNoise.noise2D( this.offset.left, this.clock ) * 4;
			this.perlin.y = PerlinNoise.noise2D( this.offset.top, this.clock ) * 3;

			this.canvas.setPerlin( this.perlin );
		}
		if( this.isDisplacement ) {
			this.canvas.setDisplacement( this.displacement );
		}

		stylizer.transform( this.el, 'translate3d(' + ( this.perlin.x + this.displacement.x ) + 'px, ' + ( this.perlin.y + this.displacement.y ) + 'px, 0)' );
	}

	calculateInteractions( delta ) {
		this.displacement 	= { x: 0, y:0 };

		for( let i = 0 ; i < this.interactions.length ; i++ ) {
			let interaction 			= this.interactions[i];
			let directorExplosion 		= new Vector2(0, 0);

			interaction.age 			+= delta * .01;
			interaction.sinFactor 		= Math.sin( interaction.age );
			interaction.timeFactor 		= interaction.durationTimeFactor / interaction.age;
			interaction.timeFactor 		= interaction.timeFactor > interaction.maxTimeFac ? interaction.maxTimeFac : interaction.timeFactor;
			interaction.timeFactor 		= interaction.timeFactor <= 1.05 ? 0 : interaction.timeFactor;
			interaction.factor 			= interaction.sinFactor * interaction.timeFactor;

			directorExplosion 			= interaction.deltaMouse.clone().normalize();

			this.displacement.x 		+= directorExplosion.x * interaction.factor;
			this.displacement.y 		+= directorExplosion.y * interaction.factor;
		}

		if( this.interactions.length && this.interactions[0].factor === 0 ) {
			this.interactions.shift();
		}
	}

	onPointerEnter( event ) {
		if( !this.isDisplacement ) {
			return;
		}

		let position = device.getPointerPosition( event );
		position.x += device.scroll.left;
		position.y += device.scroll.top;

		this.interactions.push({
			position: 			position,
			deltaMouse: 		this.deltaMouse.clone(),
			age: 				0,
			factor: 			0,
			sinFactor: 			0,
			timeFactor: 		0,
			maxTimeFac: 		30 * this.deltaMouse.length() * .2,
			durationTimeFactor: 50
		});
	}

	setDeltaMouse( delta ) {
		this.deltaMouse = delta;
	}
}
