import Component 			from '../colorz/Component';

import creator 				from '../colorz/utils/creator';
import device 				from '../colorz/utils/device';
import Vector2 				from '../colorz/utils/Vector2';

import SquareItem 			from './SquareItem';

module.exports = class SquareEngine extends Component {
	onInit( el ) {
		this.onPointermove = this.onPointermove.bind( this );

		this.el = el;

		this.deltaMouse = new Vector2(0, 0);
		this.lastMouse = new Vector2(0, 0);
		this.items = creator('.js-square-item', SquareItem);
	}

	onReady() {
		window.addEventListener(device.pointermove, this.onPointermove);
	}

	onPointermove( event ) {
		let position = device.getPointerPosition( event );
		position.x += device.scroll.left;
		position.y += device.scroll.top;

		let pos = new Vector2(position.x, position.y)

		this.deltaMouse = pos.clone().sub( this.lastMouse );

		this.lastMouse 	= pos;

		for( let i = 0 ; i < this.items.length ; i++ ) {
			this.items[i].setDeltaMouse( this.deltaMouse );
		}
	}
}