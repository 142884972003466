import Component 			from '../colorz/Component';

import getAbsoluteOffset 	from '../colorz/utils/getAbsoluteOffset';

module.exports = class IconsPlacement extends Component {
	onInit() {
		this.items 		= new Array();

		let srcs 		= document.querySelectorAll('.js-icon-src');
		let targets 	= document.querySelectorAll('.js-icon-target');

		for( let i = 0 ; i < srcs.length ; i++ ) {
			this.items.push({
				el: srcs[i],
				target: targets[i]
			})
		}
	}

	onReady() {
		this.onResize();
	}

	onLoad() {
		this.onResize();
	}

	onResize() {
		for( let i = 0 ; i < this.items.length ; i++ ) {
			let item = this.items[i].el;
			let target = this.items[i].target;

			item.style.top = '0px';
			item.style.left = '0px';

			let itemPosition = getAbsoluteOffset( item );
			let targetPosition = getAbsoluteOffset( target );

			item.style.top = targetPosition.top - itemPosition.top + 'px';
			item.style.left = targetPosition.left - itemPosition.left + 'px';
		}
	}
}