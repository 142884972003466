import creator 				from './colorz/utils/creator';
import device 				from './colorz/utils/device';

import ImageCoverWrapper 	from './colorz/core/ImageCover/ImageCoverWrapper';

import BaseCanvas 			from './components/BaseCanvas';
import SquareEngine 		from './components/SquareEngine';
import SquareWording 		from './components/SquareWording';
import IconsPlacement 		from './components/IconsPlacement';




var imagecovers 	= creator( '.js-image-cover-wrapper', ImageCoverWrapper );

var squareEngine 	= new SquareEngine();
var squareWording 	= new SquareWording();
var iconPlacement 	= new IconsPlacement();

if( device.isTrident || device.isEdge || device.isMSIE ) {
	document.body.classList.add('is-ie');
}